export const useKftLocalStorage = <T>(key: string, defaultValue: T) => {
  const data = useState(key, () => defaultValue)
  const loaded = ref(false)

  onMounted(() => {
    const storedData = localStorage.getItem(key)
    if (storedData) {
      data.value = JSON.parse(storedData)
    }
    loaded.value = true
  })

  watch(
    data,
    () => {
      localStorage.setItem(key, JSON.stringify(data.value))
    },
    { deep: true }
  )

  return data
}

import {
  getEventAddOnId,
  getEventAddOnName,
  getEventAddOnUnitPriceAmount,
} from './eventAddOnGetters'
import type {
  Cart,
  CartItem,
  EventDateCartItem,
  ShippingCost,
} from '@/composables/types/cart'
import type {
  Coupon,
  GTMItem,
  OrderItem,
  AgnosticCoupon,
  AgnosticTotals,
} from '@/composables/types/checkout'
import type {
  EventAddOn,
  EventDateChildren,
  Price,
} from '~/composables/types/event'

interface IGiftboxPrice {
  amount: number
  currency: string
  formatted: string
}

const fnGetItemValidationRemovableReasons = (): string[] => {
  return ['notAvailable', 'notFound', 'soldOut', 'minAdvance', 'maxAdvance']
}

const fnGetCartItemsLength = (cart: Cart): number => {
  return cart.items.length
}

const getGiftboxPrice = (cart: Cart): IGiftboxPrice => {
  let returnObj: IGiftboxPrice = {
    amount: 100,
    currency: '',
    formatted: '',
  }

  if (cart?.giftboxPrice) {
    returnObj = { ...cart?.giftboxPrice }
  }

  return {
    ...returnObj,
    amount: Number(returnObj.amount) / 100,
  }
}

const getGiftboxPriceAmount = (cart: Cart): string =>
  cart?.giftboxPrice?.amount ?? 0

const getShippingCost = (cart: Cart): ShippingCost | null => {
  return cart?.shippingCost || null
}

const getShippingPrice = (cart: Cart): Price | null => {
  return getShippingCost(cart)?.value || null
}

const getShippingPriceAmount = (cart: Cart): number => {
  const shippingCost = getShippingCost(cart)
  return shippingCost ? Number(shippingCost.value.amount) / 100 : 0
}

const getTotalItems = (cart: Cart): number => {
  return cart?.items?.length || 0
}

const getFormattedPrice = (price: number): string => {
  return ''
}

const getCoupons = (cart: Cart): AgnosticCoupon[] => {
  return []
}

const getDiscount = (coupon: Coupon): any => {
  return coupon?.discount || 0
}

const isGiftboxEnabled = (cart: Cart): boolean => {
  return cart?.isGiftboxEnabled || false
}

const getItems = (cart: Cart): CartItem[] => {
  return cart?.items || []
}

const getItemPermalink = (item: CartItem): string => {
  return item.permalink || ''
}

const getItemId = (item: CartItem): string => {
  return item.id || ''
}

const getEventDescriptionId = (item: CartItem): string => {
  return item.eventDescriptionId || ''
}

const getItemIdForGtm = (item: CartItem): string => {
  // for GA we want the event description ID's
  if (item.type === 'GIFT_CARD' && !getEventDescriptionId(item)) {
    const price = Number(item.price.amount) / 100
    return 'giftcard-' + price
  }
  return getEventDescriptionId(item) || ''
}

const isGiftcard = (item: CartItem): boolean => {
  return item.type === 'GIFT_CARD'
}

const hasGiftcard = (cart: Cart): boolean => {
  const length = cart.items.length

  for (let i = 0; i < length; i++) {
    if (cart.items[i].type === 'GIFT_CARD') {
      return true
    }
  }

  return false
}

const getItemsForOrder = (cart: Cart): OrderItem[] => {
  const length = cart?.items?.length
  const items: OrderItem[] = []

  for (let i = 0; i < length; i++) {
    const item = cart.items[i]

    if (item) {
      if (item.type === 'EVENT') {
        items.push({
          type: 'EVENT',
          product: item.id,
          quantity: item.quantity,
          private: (item as EventDateCartItem).private,
        })
      }

      if (item.type === 'GIFT_CARD') {
        const supplierId = item.supplierId
        items.push({
          type: 'GIFT_CARD',
          event: item.id,
          quantity: item.quantity,
          value: item.price?.amount,
          ...(supplierId && { supplier_id: item.supplierId }),
          ship: isGiftboxEnabled(cart),
        })
      }
    }
  }

  return items
}

const getItemsForGtm = (cart: Cart): GTMItem[] => {
  const length = cart.items.length
  const items: GTMItem[] = []

  let index = 0

  for (let i = 0; i < length; i++) {
    const item = cart.items[i]
    const price = getUnitItemPrice(item)
    items.push({
      index,
      item_id: getItemIdForGtm(item),
      item_name: item.title,
      item_category: getItemMainCategory(item),
      item_variant: getItemVariantForGtm(item),
      quantity: item.quantity,
      price: price?.toFixed(2) || price,
    })

    const addon = getItemAddon(item)

    if (addon) {
      const addonPrice = Number(
        (getEventAddOnUnitPriceAmount(addon) || 0) / 100
      )
      index++
      items.push({
        index,
        item_id: `addon-${getEventAddOnId(addon)}` || '',
        item_name: getEventAddOnName(addon) || '',
        item_category: 'ADD_ON',
        item_variant: 'ADD_ON',
        quantity: item.quantity,
        price: addonPrice?.toFixed(2) || addonPrice,
      })
    }
    index++
  }

  return items
}

const getItemMainCategory = (item: CartItem): string => {
  if (item.type === 'GIFT_CARD') {
    return 'giftcard'
  }
  return item.category || ''
}

const getItemVariantForGtm = (item: CartItem): string => {
  if (item.type === 'GIFT_CARD') {
    return item.type
  }

  if (item.private === true) {
    return 'PRIVATE_EVENT'
  }
  // default will be "EVENT"
  return item.type
}

const getCartHasItem = (cart: Cart, id: string): boolean => {
  return cart.items.some((item: CartItem) => item.id === id)
}

const getItemName = (item: CartItem): string => {
  return item.title || ''
}

const getItemImage = (item: CartItem): string => {
  return item.thumbnail || ''
}

const getItemPrice = (item: CartItem): number => {
  const privateMinTickets = Number(item?.privateMinTickets) || 1
  const qty =
    item.private && privateMinTickets > item.quantity
      ? item.privateMinTickets
      : item.quantity

  if (!qty) return 0

  return (Number(item.price.amount) * qty) / 100
}

export const getAllAddonsForOrder = (
  cart: Cart
): { quantity: number; addon: string; parent: string; type: string }[] => {
  return cart.items
    .filter((item) => item.addOn)
    .map((item) => ({
      addon: item.addOn!.id,
      type: item.addOn!.inventory_type,
      quantity: item.quantity,
      parent: item.id,
    }))
}

const getFirstAddOnName = (cart: Cart): string => {
  const firstItemWithAddOn = cart?.items?.find((item) => item?.addOn)
  return firstItemWithAddOn?.addOn?.name
}

// Todo:
// Now only handling price type individual. That means
// n add ons for n tickets.
const getAddOnPrice = (item: EventDateCartItem): number => {
  const privateMinTickets = Number(item?.privateMinTickets) || 1
  const qty =
    item.private && privateMinTickets > item.quantity
      ? item.privateMinTickets
      : item.quantity

  if (!qty) return 0

  return (Number(item?.addOn?.unit_price?.amount) * qty) / 100
}

const getTotalAddOnPrice = (cart: Cart) =>
  cart?.items.reduce((total, item) => {
    if (item?.addOn && item?.addOn.unit_price) {
      const addonsQty = item.private
        ? Math.max(Number(item.privateMinTickets), item.quantity)
        : item.quantity
      return total + Number(item?.addOn.unit_price.amount / 100) * addonsQty
    }
    return total
  }, 0)

const getUnitItemPrice = (item: CartItem): number => {
  const privateMinTickets = item?.privateMinTickets || 1
  const price = Number(item?.price?.amount)
  const qty = item?.quantity

  if (item?.dateTypeToBeBooked === 'PRIVATE') {
    if (privateMinTickets > qty) {
      return (price * privateMinTickets) / qty / 100
    }
  }

  return price / 100
}

const getItemPriceAmount = (item: CartItem): number => {
  return Number(item.price.amount)
}

const getItemQty = (item: CartItem): number => {
  return item.quantity || 1
}

const getAvailableStock = (item: CartItem): number => {
  if (item.type === 'GIFT_CARD') {
    return 999999
  }

  if (item.private) {
    return item?.privateMaxTickets || item.availableTicketsQuantity || 1
  }

  return item.availableTicketsQuantity || 1
}

const isPrivate = (item: CartItem): boolean => {
  return item.private || false
}

const getCartTotalPrice = (cart: Cart, isTotal: boolean): number => {
  if (!cart || !cart.items) {
    return 0
  }

  let total = 0
  for (let i = 0; i < cart.items.length; i++) {
    const item = cart.items[i]

    total += getItemPrice(item)

    if ((item as EventDateCartItem)?.addOn) {
      total += getAddOnPrice(item as EventDateCartItem)
    }

    if (isGiftcard(item) && cart.isGiftboxEnabled) {
      total += getGiftboxPrice(cart).amount
    }
  }

  if (cart.isGiftboxEnabled && isTotal) {
    total += getShippingPriceAmount(cart)
  }

  return total
}

const getTotals = (cart: Cart): AgnosticTotals => {
  return {
    total: getCartTotalPrice(cart, true),
    subtotal: getCartTotalPrice(cart, false),
    special: undefined,
  }
}

const getItemAddon = (cartItem: CartItem): EventAddOn | undefined => {
  return cartItem?.addOn
}

export const cartGetters = {
  getTotals,
  getShippingPrice,
  getItems,
  getItemName,
  getItemImage,
  getItemPrice,
  getFirstAddOnName,
  getAddOnPrice,
  getTotalAddOnPrice,
  getItemId,
  getItemQty,
  getFormattedPrice,
  getTotalItems,
  getCoupons,
  getDiscount,
  getAvailableStock,
  getCartHasItem,
  getItemPermalink,
  getCartTotalPrice,
  getItemsForOrder,
  isGiftboxEnabled,
  getGiftboxPrice,
  getGiftboxPriceAmount,
  isGiftcard,
  hasGiftcard,
  getItemsForGtm,
  getItemMainCategory,
  fnGetItemValidationRemovableReasons,
  fnGetCartItemsLength,
  isPrivate,
  getUnitItemPrice,
  getItemPriceAmount,
  getItemVariantForGtm,
  getItemIdForGtm,
  getEventDescriptionId,
  getAllAddonsForOrder,
  getItemAddon,
}
